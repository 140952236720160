app.components.text = {
    onReady: function () {
        if ($('.wysiwyg').length) {
            return true;
        }
    },

    addListener: function () {
        const that = this;

        $('.wysiwyg').each(async function () {
            const $this = $(this);
            const pattern = /<!--\[encrypted\][\s\S]*?\[\/encrypted\]-->/g;
            const textDecrypted = $this.html();
            $this.html(await that.replaceEncryptedText(textDecrypted, pattern));
        });
    },

    /**
     * Replaces encrypted text with decrypted text within the given element text.
     *
     * @param {string} elementText - The text content of the element.
     * @param {RegExp} pattern - The regular expression pattern to match encrypted text.
     * @return {Promise<string>} The text with decrypted content replacing encrypted text.
     */
    replaceEncryptedText: async function (elementText, pattern) {
        const matches = elementText.match(pattern);
        if (!matches) return elementText;

        const replacements = await Promise.all(matches.map(async (match) => {
            const decryptedText = match
                .replace(/\[encrypted\]/g, '')
                .replace(/\[\/encrypted\]/g, '')
                .replace(/<!--?/g, '')
                .replace(/-->?/g, '');

            const decryptedArray = JSON.parse(atob(decryptedText));
            return this.decryptText(decryptedArray);
        }));

        let replacedText = elementText;
        matches.forEach((match, index) => {
            replacedText = replacedText.replace(match, replacements[index]);
        });

        return replacedText;
    },

    /**
     * Decrypts the text using the provided decrypted array containing key, iv, and content.
     *
     * @param {Object} decryptedArray - The array containing base64 encoded key, iv, and content.
     * @return {Promise<string>} The decrypted text.
     */
    decryptText: async function (decryptedArray) {
        const keyBuffer = Uint8Array.from(atob(decryptedArray.key), c => c.charCodeAt(0));
        const ivBuffer = Uint8Array.from(atob(decryptedArray.iv), c => c.charCodeAt(0));
        const ciphertextBuffer = Uint8Array.from(atob(decryptedArray.content), c => c.charCodeAt(0));

        const cryptoKey = await crypto.subtle.importKey("raw", keyBuffer, {name: "AES-CBC"}, false, ["decrypt"]);
        const decryptedBuffer = await crypto.subtle.decrypt({name: "AES-CBC", iv: ivBuffer}, cryptoKey, ciphertextBuffer);
        const decoder = new TextDecoder();

        return decoder.decode(new Uint8Array(decryptedBuffer));
    }
};
