app.components.news = {
    onReady: function () {
        if ($('.news').length) {
            return true;
        }
    },
    addListener: function () {
        let that = this;

        let $container = $('.news'),
            $select = $('.news__filter button');

        $select.click(function () {
            let button = $(this);
            let component = button.data('component');
            let template = button.data('template');
            let page = parseInt(button.data('page'));
            let category = button.val();
            $(button).siblings().removeClass('active');
            $(button).addClass('active');

            history.pushState({}, "", button.data('href'));
            that.getNewsEntries(component, template, category, page);
        });

        $('button.load', $container).on('click', function () {
            let button = $(this);
            let component = button.data('component');
            let template = button.data('template');
            let page = parseInt(button.data('page'));
            let category = button.data('category');
            that.getNewsEntries(component, template, category, page);
            return false;
        });

        let hash = window.location.hash;
        if (hash && $(hash).length) {
            $(hash).trigger('click');
        }
    },
    getNewsEntries: function (component, template, category, page) {
        let $container = $('.news');
        let button = $('button.load', $container);
        let options = ['template:' + template, 'page:' + page, 'category:' + category];
        let endpoint = '?component=' + component + '&options=' + options.join(';');
        button.attr('disabled', 'disabled');
        button.fadeIn(250);
        $.get(endpoint, function (data) {
            if (data) {
                if (page > 1) {
                    $('.news .container', $container).append(data);
                } else {
                    $('.news .container', $container).html(data);
                }
                button.data('page', page + 1);
                button.data('category', category);
                button.prop("disabled", true).removeAttr("disabled");
            } else {
                button.fadeOut(250);
            }
            $('*', $container).removeClass('loading');
        });
    }
};
