app.components.woocommerce_variations = {
    onReady: function () {
        if ($('.variations_form.cart').length) {
            return true;
        }
    },

    addListener: function () {

        $('.woocommerce-product-gallery__image a').css({'cursor': 'default'}).on('click', function () {
            return false;
        })

        if ($('html[lang="fr-FR"] select#sprache').length) {
            $('html[lang="fr-FR"] select#sprache').val('Français').trigger('change');
        }

        if ($('html[lang="it-IT"] select#sprache').length) {
            $('html[lang="it-IT"] select#sprache').val('Italico').trigger('change');
        }

        /**
         * Updates the product variation options with price differences from the minimum price.
         */
        if ($('#produktart').length) {
            const variations = $('form.variations_form').data('product_variations');
            if (!variations || variations.length === 0) return;

            let min_price = Math.min(...variations.map(variation => variation.display_price));

            variations.forEach(variation => {
                if (variation.display_price > min_price) {
                    const name = variation.attributes.attribute_produktart;
                    const priceDifference = variation.display_price - min_price;

                    $('#produktart option').each(function () {
                        const option = $(this);
                        if (name === option.text()) {
                            option.text(`${name} (+CHF ${priceDifference})`);
                        }
                    });
                }
            });
        }

        $(document).on('click', '.single_add_to_cart_button:not(.disabled)', function (e) {

            var $thisbutton = $(this),
                $form = $thisbutton.closest('form.cart'),
                //quantity = $form.find('input[name=quantity]').val() || 1,
                //product_id = $form.find('input[name=variation_id]').val() || $thisbutton.val(),
                data = $form.find('input:not([name="product_id"]):not([disabled]), select, button, textarea').serializeArrayAll() || 0;

            $.each(data, function (i, item) {
                if (item.name == 'add-to-cart') {
                    item.name = 'product_id';
                    item.value = $form.find('input[name=variation_id]').val() || $thisbutton.val();
                }
            });

            e.preventDefault();

            $(document.body).trigger('adding_to_cart', [$thisbutton, data]);

            $.ajax({
                type: 'POST',
                url: woocommerce_params.wc_ajax_url.toString().replace('%%endpoint%%', 'add_to_cart'),
                data: data,
                beforeSend: function (response) {
                    $thisbutton.prop('disabled', true);
                    $thisbutton.removeClass('added').addClass('loading');
                },
                complete: function (response) {
                    $thisbutton.prop('disabled', false);
                    $thisbutton.addClass('added').removeClass('loading');
                },
                success: function (response) {

                    if (response.error && response.product_url) {
                        window.location = response.product_url;
                        return;
                    }

                    $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, $thisbutton]);

                    $.each(response.fragments, function (key, value) {
                        $(key).replaceWith(value);
                    });
                },
            });

            return false;

        });

    }
};

