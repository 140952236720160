class TableComponent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        const table = this.querySelector('table');
        if (table) {
            const container = document.createElement('div');
            container.className = 'table-container';

            const wrapper = document.createElement('div');
            wrapper.className = 'table-wrapper';
            wrapper.appendChild(container);

            table.parentNode.insertBefore(wrapper, table);
            container.appendChild(table);

            this.setupEventListener();
        }
    }

    setupEventListener() {
        const container = this.querySelector('.table-container');

        container.addEventListener('scroll', () => {
            // Adding class based on scroll position
            if (container.scrollLeft > 0) {
                container.closest('.table-wrapper').classList.add('table-wrapper--hasScrolled');
            }
        });

        window.addEventListener('resize', () => {
            // Handling resizing of window to adjust table wrapper
            const table = this.querySelector('table');
            if (table) {
                const wrapper = table.closest('.table-wrapper');
                wrapper.classList.remove('table-wrapper--scroll');
                if (table.offsetWidth > table.parentElement.offsetWidth) {
                    wrapper.classList.add('table-wrapper--scroll');
                }
            }
        });

    }
}

customElements.define('table-component', TableComponent);
