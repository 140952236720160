class TeaserComponent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.addEventListener('click', this.handleClick);
    }

    handleClick(event) {
        event.preventDefault();
        const links = Array.from(this.querySelectorAll('a'));
        if (links.length === 1) {
            const link = links[0].href;
            if (link) {
                window.location.href = link;
            }
        }
        return false;
    }

    disconnectedCallback() {
        this.removeEventListener('click', this.handleClick);
    }
}

customElements.define('teaser-component', TeaserComponent);
