class AccordionComponent extends HTMLElement {
    constructor() {
        super();
        this.container = null;
    }

    connectedCallback() {

        const that = this;

        $(document).on('click', 'accordion-component .accordion__header', function () {
            const $header = $(this);
            $header.toggleClass('accordion__header--active')
                .attr('aria-expanded', (i, ariaExpanded) => !ariaExpanded);
            $header.next().slideToggle('fast');

            $('.accordion__header').not($header)
                .removeClass('accordion__header--active')
                .attr('aria-expanded', false);
            $(".accordion__content").not($header.next()).slideUp('fast');
        });

        $(window).on('hashchange', () => {
            const hash = window.location.hash;
            if (hash) {
                const $targetHeader = $(hash + ' .accordion__header');
                if ($targetHeader.length) {
                    $targetHeader.trigger('click');
                }
            }
        }).trigger('hashchange');

        if (this.querySelector('.accordion__search')) {
            that.initSearch();
        }
    }

    initSearch() {
        const that = this;

        Promise.all([
            import(/* webpackChunkName: "fuse" */ 'fuse.js'),
            import(/* webpackChunkName: "lodash" */ 'lodash')
        ]).then(([FuseModule, LodashModule]) => {
            const Fuse = FuseModule.default;
            const _ = LodashModule.default;

            that.$searchbox = $('accordion-component input.accordion__search-input');
            that.$results = $('accordion-component .accordion__items');
            that._index = JSON.parse(atob(accordion_search_index));

            that._search = new Fuse(that._index, {
                shouldSort: true,
                includeScore: true,
                threshold: 0.3,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: ["headline", "content"]
            });

            that._tmpl = _.template($('#tmpl-fuse-hit').html());
            that.$searchbox.on('keyup', () => {
                const value = $.trim(that.$searchbox.val());
                that.doSearch(value);
            });
        }).catch(error => {
            console.error('Error loading modules:', error);
        });

    }

    doSearch(value) {
        const that = this;
        const searchResults = value ? that._search.search(value) : that._index;
        let searchHtml = '';
        searchResults.forEach(result => {
            if (result.item) result = result.item;
            searchHtml += that._tmpl({data: result});
        });
        that.$results.html(searchHtml);
    }
}

customElements.define('accordion-component', AccordionComponent);
