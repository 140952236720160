import 'jquery-match-height';

$(function () {

    const isSameClassNames = ($columns) => {
        let lastClassNames = '';
        for (let i = 0; i < $columns.length; i++) {
            const currentClassNames = $columns.eq(i).attr('class');
            if (lastClassNames && currentClassNames !== lastClassNames) {
                return false;
            }
            lastClassNames = currentClassNames;
        }
        return true;
    }

    $('main .container .col[data-name="components"] .container').each(function () {
        const columns = $(this).find('.col');
        if (columns.length > 1 && isSameClassNames(columns)) {
            columns.matchHeight({
                byRow: false
            });
        }
    });

    $('.col[data-name="woocommerce"] .products .product, .news .post').matchHeight({
        byRow: true
    });

});

