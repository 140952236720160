class MarqueeComponent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
    }

    disconnectedCallback() {
    }
}

customElements.define('marquee-component', MarqueeComponent);
