app.components.navigation = {
    onReady: function () {
        if ($('.navigation__toggle').length) {
            return true;
        }
    },
    addListener: function () {

        $('.navigation__toggle').on("click", function () {
            $('html').toggleClass('mobile-nav-is-visible');
            return false;
        });

        $('html').click(function (e) {
            if (window.innerWidth > 1024) {
                if ($('html').hasClass('mobile-nav-is-visible')) {
                    if ($(e.target).is("body")) {
                        $('.navigation__toggle').trigger("click");
                    }
                }
            }
        });

    }
};
