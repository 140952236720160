class IframeComponent extends HTMLElement {
    constructor() {
        super();
        this.iframe = null;
    }

    connectedCallback() {
        this.iframe = this.querySelector('iframe');
        if (!this.iframe) {
            console.error('Iframe element not found');
            return;
        }

        this.resize(); // Initial resize
        window.addEventListener('resize', () => this.resize()); // Resize on window resize
    }

    resize() {
        if (!this.iframe) {
            console.error('Resize called before iframe is initialized');
            return;
        }

        const aspectRatio = this.iframe.dataset.aspectRatio;
        if (!aspectRatio) {
            console.error('Aspect ratio data attribute is missing');
            return;
        }

        const width = this.iframe.clientWidth;
        const height = width / aspectRatio;
        this.iframe.style.height = `${height}px`;
    }

    disconnectedCallback() {
        window.removeEventListener('resize', this.resize);
    }
}

customElements.define('iframe-component', IframeComponent);
