app.components.button = {
    onReady: function () {
        if ($('.button-primary, .button-secondary').length) {
            return true;
        }
    },
    addListener: async function () {
        $('.button-primary, .button-secondary').not('.icon-only').each(function () {
            let button = $(this);
            button.addClass('flip');
            if (button.children('span[data-hover]').length === 0) {
                let text = button.text();
                button.html('<span data-hover="' + text + '">' + text + '</span>');
            }
        });
    }
};
