app.components.woocommerce_myaccount = {
    onReady: function () {
        if ($('body.woocommerce-account').length) {
            return true;
        }
    },

    addListener: function () {
        const navigation = $('nav.woocommerce-MyAccount-navigation');
        if ( navigation.find('li.is-active').length === 0 ) {
            navigation.find('li').first().addClass('is-active');
        }
    }
};

