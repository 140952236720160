app.components.woocommerce_productcompose = {
    onReady: function () {
        if ($('.woocommerce-productcompose').length) {
            return true;
        }
    },
    addListener: function () {

        $('.woocommerce-productcompose--button').on('click', function () {
            $('html').toggleClass('productcompose-open');


            if ($('html').hasClass('productcompose-open')) {
                
                $('.woocommerce-productfilter .woocommerce-productfilter-1 .jcf-checkbox').removeClass('jcf-checked').addClass('jcf-unchecked');
                $('.woocommerce-productfilter .woocommerce-productfilter-1 input').prop('checked', false);
    
                $('.woocommerce-productfilter .woocommerce-productfilter-1 li:last-child .jcf-checkbox').removeClass('jcf-unchecked').addClass('jcf-checked');
                $('.woocommerce-productfilter .woocommerce-productfilter-1 li:last-child input').prop('checked', true);
    
                $('.woocommerce-productfilter .woocommerce-productfilter-1 input').trigger('change');


                window.scrollTo({

                    top: $('#woocommerce-products').offset().top,
                    behavior: "smooth"
                });
                
            } else {

            }


            return false;
        });


    }
}
