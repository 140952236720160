// Remove 'no-js' class indicating JavaScript availability.
$("html").removeClass("no-js");

// Add target="_blank" to PDF links.
$("a[href$='pdf']").attr("target", "_blank");

// Open External Links In New Window.
$('a').each(function () {
    if ($(this).attr("href") && ($(this).attr("href").indexOf("#") === 0 || $(this).attr("href").indexOf("javascript") === 0)) {
        return;
    }

    var a = new RegExp('/' + window.location.host + '/');
    var b = new RegExp(/(mailto:.*)/g);

    if (!a.test(this.href) && !b.test(this.href)) {
        $(this).attr('rel', 'external');
        $(this).attr("target", "_blank");
    } else {
        $(this).attr('rel', 'internal');
    }
});

// Add 'hasImage' class to links that contain images.
$('a:has(img)').addClass('hasImage');

// Event listener for hash changes.
$(window).on('hashchange', function () {
    var hash = window.location.hash;
    $('html.mobile-nav-is-visible').removeClass('mobile-nav-is-visible');
    if (hash) {
        setTimeout(() => {
            scrollToElement(hash);
        }, 100);
    }
}).trigger('hashchange');

// Event listener for internal hash links.
$(document).on('click', 'header a[href^="#"], main a[href^="#"], footer a[href^="#"]', function (event) {
    var hash = $.attr(this, 'href');
    if ($(hash).length && hash !== '#') {
        event.preventDefault();
        scrollToElement(hash);
        history.pushState(null, null, hash);
    }
});
